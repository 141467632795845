import type { OptionContent } from "@aion-dk/js-client/dist/lib/av_client/types";
import i18n from "./i18n";

interface Voter {
  identifier: string,
  name: string,
  optionReferences: string[],
  voted: boolean,
  weight: number,
  updatedAt: string,
};

const { t } = i18n.global;

const optionTitle = (options: OptionContent[], optionReference: string, locale: string) => {
  if (optionReference === "blank") return t("js.handraise.list_view.blank");
  if (optionReference === "not_voted") return t("js.handraise.list_view.not_voted_yet");
  return options.find((option: OptionContent) =>
    option.reference === optionReference)?.title[locale];
};

const optionColorStyle = (options: OptionContent[], optionReference: string, blankAccentColor: string | null = null) => {
  if (optionReference === "blank" && blankAccentColor) return `background-color: ${blankAccentColor};`;
  if (optionReference === "blank") return "";
  if (optionReference === "not_voted") return "";

  const accentColor = options.find((option: OptionContent) =>
    option.reference === optionReference)?.accentColor;

  return `background-color: ${accentColor};`;
};

const optionColorClass = (optionReference: string, blankAccentColor: string | null = null) => {
  if (optionReference === "blank" && !blankAccentColor) return "option-color-blank";
  if (optionReference === "not_voted") return "option-color-default";
  return "";
};

const optionPosition = (options: OptionContent[], optionReference: string, index: number) => {
  if (optionReference === "not_voted") return `order: ${options.length + 2};`;
  if (optionReference === "blank") return `order: ${options.length + 1};`;
  return `order: ${index - 1};`;
};

const orderVotersByName = (voters: Voter[]) => {
  return voters.sort((vote1, vote2) => {
    return vote1.name.localeCompare(vote2.name);
  });
};

const orderVotersByTimestamp = (voters: Voter[]) => {
  return voters.splice(0, 30).sort((vote1, vote2) => {
    const date1 = new Date(vote1.updatedAt).getTime();
    const date2 = new Date(vote2.updatedAt).getTime();
    return date2 - date1;
  });
};

const orderOptionsByVotes = (voters: Voter[]) => {
  const filteredOptions = Object.keys(voters).filter(option => option !== "not_voted");

  const sorted = filteredOptions.sort(
    (optRef1, optRef2) => {
      const votes1 = voters[optRef1].votes.length;
      const votes2 = voters[optRef2].votes.length;
      return votes2 - votes1;
    }
  );

  sorted.push("not_voted");
  return sorted;
};

export type { Voter };
export {
  optionTitle,
  optionColorStyle,
  optionColorClass,
  optionPosition,
  orderVotersByName,
  orderVotersByTimestamp,
  orderOptionsByVotes,
};
